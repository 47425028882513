/* General Styling */
body {
  margin: 0;
  padding: 0;
  background-color: #121212;
  color: #ffffff;
  font-family: "Roboto", sans-serif;
  line-height: 1.6;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #e0e0e0;
}

a {
  color: #bb86fc;
  text-decoration: none;
}

a:hover {
  color: #3700b3;
}

/* Input and Button Styling */
input,
button {
  padding: 12px 15px;
  border-radius: 8px;
  border: none;
  margin: 10px 0;
  font-size: 1rem;
  width: 100%;
  max-width: 320px;
  background-color: #1f1f1f;
  color: #ffffff;
  transition: all 0.3s ease-in-out;
}

input:focus,
button:focus {
  outline: none;
  box-shadow: 0 0 10px #bb86fc;
}

button {
  background-color: #bb86fc;
  cursor: pointer;
}

button:hover {
  background-color: #3700b3;
  transform: translateY(-2px);
}

/* Containers */
.login-container,
.content-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  padding: 20px;
  background: linear-gradient(145deg, #1e1e1e, #121212);
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.5);
  border-radius: 12px;
}

/* Media Display Styling */
img,
video {
  max-width: 90%;
  max-height: 70vh;
  margin-bottom: 20px;
  border-radius: 12px;
  border: 3px solid #bb86fc;
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.6);
}

/* Container Styling */
.content-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  padding: 20px;
  background: linear-gradient(145deg, #1e1e1e, #121212);
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.5);
  border-radius: 12px;
  position: relative; /* To position the logout button */
}

/* Logout Button Styling */
.logout-button {
  position: absolute;
  top: 20px;
  right: 20px;
  padding: 8px 12px;
  background-color: #bb86fc;
  color: #ffffff;
  border: none;
  border-radius: 6px;
  font-size: 0.9rem;
  cursor: pointer;
  width: auto;
  transition: background-color 0.3s ease-in-out;
}

.logout-button:hover {
  background-color: #3700b3;
}

/* Media Display Styling */
img,
video {
  max-width: 90%;
  max-height: 70vh;
  margin-top: 20px;
  border-radius: 12px;
  border: 3px solid #bb86fc;
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.6);
}

/* Responsive Design */
@media (max-width: 768px) {
  input,
  button {
    font-size: 0.9rem;
    padding: 10px;
  }
}

@media (max-width: 480px) {
  body {
    font-size: 0.9rem;
  }

  .login-container,
  .content-container {
    padding: 15px;
  }

  img,
  video {
    max-width: 100%;
    max-height: 60vh;
  }
}
